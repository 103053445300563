import { getFormProps, getInputProps, useForm } from "@conform-to/react";
import { $path } from "remix-routes";
import { Button } from "./button";
import { Form } from "./form";

export function OptoutForm({
	value,
	buttonVariant,
	buttonLabel = "Accept all",
}: {
	value: "accepted" | "rejected";
	buttonVariant: "default" | "secondary";
	buttonLabel?: string;
}) {
	const formAction = $path("/resources/optout");
	const [form, fields] = useForm({
		id: `optout-${value}-form`,
		defaultValue: {
			value,
		},
		shouldRevalidate: "onBlur",
	});

	return (
		<Form
			{...getFormProps(form)}
			action={formAction}
			className="col-span-6"
			context={form.context}
			method="POST"
			navigate={false}
			reloadDocument
		>
			<input {...getInputProps(fields.value, { type: "hidden" })} />

			<Button type="submit" $variant={buttonVariant} $size="sm" className="whitespace-nowrap">
				{buttonLabel}
			</Button>
		</Form>
	);
}
