import { useToast } from "#app/hooks/use-toast";

import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from "./toast";

export function Toaster() {
	const { toasts } = useToast();

	return (
		<ToastProvider>
			{toasts.map(({ id, title, description, action, dismissable = true, ...props }) => (
				<Toast key={id} {...props}>
					<div className="grid gap-1 flex-1">
						{title && <ToastTitle>{title}</ToastTitle>}
						{description && <ToastDescription>{description}</ToastDescription>}
					</div>
					{action}
					{dismissable && <ToastClose />}
				</Toast>
			))}
			<ToastViewport />
		</ToastProvider>
	);
}
